import Flickity from 'flickity';

function updateGalleryCaption(flkty) {
  const galleryCaption = flkty.element.parentNode.querySelector('.gallery-caption');
  const selectedCaption = flkty.selectedElement.querySelector('.gallery-item-caption');
  galleryCaption.innerHTML = (selectedCaption) ? selectedCaption.innerHTML : '';
}

function setupGalleryCaptions(flkty) {
  const caption = flkty.element.querySelector('.gallery-item-caption');

  if (caption) {
    const captionWrapper = document.createElement('div');
    captionWrapper.classList.add('gallery-caption');
    flkty.element.parentNode.appendChild(captionWrapper);

    // Update caption on slide change.
    flkty.on('select', () => {
      updateGalleryCaption(flkty);
    });

    // Set initial caption.
    updateGalleryCaption(flkty);
  }
}

function toggleFullscreen(button, flkty) {
  const gallery = flkty.element.parentNode;
  const isFullscreen = gallery.classList.contains('fullscreen');

  if (isFullscreen) {
    gallery.classList.remove('fullscreen');
    button.classList.remove('is-active');
    document.documentElement.style = null;
  } else {
    gallery.classList.add('fullscreen');
    button.classList.add('is-active');
    document.documentElement.style.overflow = 'hidden';
  }

  flkty.resize();
}

function initGalleries(context) {
  const galleries = context.querySelectorAll('.gallery-content');

  [].forEach.call(galleries, (gallery) => {
    const flkty = new Flickity(gallery, {
      cellSelector: '.gallery-item',
      cellAlign: 'left',
      setGallerySize: false,
      arrowShape: 'M32.7,50L59.5,0l7.7,4.2L42.6,50l24.6,45.8l-7.7,4.2L32.7,50z'
    });

    // Setup captions.
    setupGalleryCaptions(flkty);

    // Fullscreen button.
    const fullscreenButton = gallery.parentNode.querySelector('.gallery-fullscreen-button');

    fullscreenButton.addEventListener('click', () => {
      toggleFullscreen(fullscreenButton, flkty);
    }, false);
  });
}

function init() {
  const siteMain = document.getElementById('site-main');
  initGalleries(siteMain);
}

export default {
  init,
  initGalleries
};
