import dfpConfig from './dfp-config';
import Advert from './Advert';

const header = document.getElementById('site-header');
let headerHeight = 0;

function getHeaderHeight() {
  return headerHeight;
}

function addHeaderAdBodyClass(e) {
  const bodyClass = (e.size[0] === 970 || e.size[0] === 320) ? 'is-header-adUnit-billboard' : 'is-header-adUnit-native';
  document.body.classList.add(bodyClass);
}

function setHeaderHeight() {
  const headerAd = document.querySelector(`${dfpConfig.zoneSelector()}--header_premium`);
  headerHeight = headerAd.offsetHeight;
}

function addBodyClassAndHeaderHeight(e) {
  const adUnitPath = e.slot.getAdUnitPath();
  const isPremium = adUnitPath.indexOf('header_premium');
  if (e.isEmpty === false && isPremium !== -1) {
    addHeaderAdBodyClass(e);
    setHeaderHeight();
  }
}

function insertPremiumHeaderZoneAndSlot() {
  const zone = Advert.createZone('header_premium');
  document.body.insertBefore(zone, header);
  window.googletag.cmd.push(() => {
    window.googletag.pubads()
      .addEventListener('slotRenderEnded', addBodyClassAndHeaderHeight);
  });
}

function init() {
  if (header) {
    insertPremiumHeaderZoneAndSlot();
  }
}

export default {
  init,
  getHeaderHeight
};
