function init() {
  if (!document.querySelector('.search-results')) {
    return;
  }

  const dataLayer = window.dataLayer || [];
  if (!window.dataLayerVars) {
    return;
  }
  // dataLayer.push(window.dataLayerVars);

  const feedContainer = document.querySelector('#feed-main');
  feedContainer.addEventListener('click', (e) => {
    const target = e.target.closest('.feed-item');
    const nodes = Array.prototype.slice.call(feedContainer.children);
    window.dataLayerVars = {
      ...window.dataLayerVars,
      event: 'custom.search.click',
      result: target.querySelector('h3 a').innerText,
      position: nodes.indexOf(target)
    };
    // console.log('dataLayerVars', window.dataLayerVars);
    dataLayer.push(window.dataLayerVars);
  });
}

export default {
  init
};
