import SmoothScroll from 'smooth-scroll';
import '../utilities/requestAnimationFrame';
import InclusiveToggle from '../utilities/InclusiveToggle';
import HeaderPremiumAdvert from '../dfp/HeaderPremiumAdvert';

const siteHeader = document.getElementById('site-header');
const navBar = document.getElementById('navBar');
const bifteki = document.querySelectorAll('.bifteki');
const myAccount = document.querySelectorAll('.myAccountBtn');
const userLogin = document.querySelectorAll('.userLoginBtn');
const pelcroToggle = document.querySelector('.navBar-action.pelcroToggle');
const navInner = document.querySelector('.navCta-wrap');
const navInnerMobile = document.querySelector('.navCta-wrap-mobile');
const digiSub = document.querySelector('.digiSub');
const digiSubMobile = document.querySelector('.digiSub--min');

const isTransparent = navBar.classList.contains('is-transparent');
const scroll = new SmoothScroll();

// Appear when scrolling up.
const navbarHeight = navBar.offsetHeight;
let fixedTimeout = null;
let scrolled = false;
let scrollY = 0;
let prevScrollY = 0;
let ticking = false;

function accountCloseState() {
  if (window.pelcroActions) {
    window.pelcroActions.close();
  }
}

function navBarCloseState() {
  siteHeader.classList.remove('is-open');
  document.body.classList.remove('is-open');
}

function accountOpenState() {
  navBarCloseState();

  if (window.pelcroActions) {
    window.pelcroActions.dashboard();
  }
}

function navBarOpenState() {
  accountCloseState();

  if (siteHeader.offsetTop !== 0) {
    scroll.animateScroll(
      navBar, // scroll target.
      0, // no trigger.
      {
        speed: 500
      }
    );
    // set timeout to allow scroll to finish.
    setTimeout(() => {
      siteHeader.classList.add('is-open');
      document.body.classList.add('is-open');
    }, 500);
  } else {
    siteHeader.classList.add('is-open');
    document.body.classList.add('is-open');
  }
}

function loginOpenState() {
  // console.log('loginOpenState');

  navBarCloseState();
  accountCloseState();

  if (window.pelcroActions) {
    window.pelcroActions.login();
  }
}

function loginCloseState() {
  if (window.pelcroActions) {
    window.pelcroActions.login();
  }
}

function initNavBarToggle() {
  bifteki.forEach((btn) => {
    const biftekiToggle = new InclusiveToggle(btn);
    biftekiToggle.init();
    btn.addEventListener('open', navBarOpenState, false);
    btn.addEventListener('close', navBarCloseState, false);
  });
}

function initAccountToggle() {
  if (myAccount != null && myAccount.length > 0) {
    myAccount.forEach((btn) => {
      const myAccountToggle = new InclusiveToggle(btn);
      myAccountToggle.init();
      btn.addEventListener('open', accountOpenState, false);
      btn.addEventListener('close', accountCloseState, false);
    });
  }

  if (userLogin !== null && userLogin.length > 0) {
    userLogin.forEach((btn) => {
      const loginToggle = new InclusiveToggle(btn);
      loginToggle.init();

      btn.addEventListener('open', loginOpenState, false);
      btn.addEventListener('close', loginCloseState, false);
    });
  }
}

function setScrolledClass() {
  if (scrollY > navbarHeight + HeaderPremiumAdvert.getHeaderHeight()) {
    // Only set when initially scrolled.
    if (!scrolled) {
      document.body.classList.add('is-scrolled');
      navBar.classList.remove('is-transparent');

      // Change positioning after transition finishes.
      fixedTimeout = window.setTimeout(function () {
        siteHeader.classList.add('is-fixed');
      }, 300);

      scrolled = true;
    }

    // Moving up.
    if (scrollY < prevScrollY) {
      document.body.classList.add('is-scrolling-up');
      // Moving down.
    } else {
      document.body.classList.remove('is-scrolling-up');
    }
  } else if (scrollY === 0 || (scrollY < HeaderPremiumAdvert.getHeaderHeight())) {
    clearTimeout(fixedTimeout);
    document.body.classList.remove('is-scrolled', 'is-scrolling-up');
    siteHeader.classList.remove('is-fixed');
    scrolled = false;
    if (isTransparent) {
      navBar.classList.add('is-transparent');
    }
  }

  ticking = false;
}

function requestTick() {
  if (!ticking) {
    window.requestAnimationFrame(setScrolledClass);
  }

  ticking = true;
}

function onScroll() {
  prevScrollY = scrollY;
  scrollY = window.pageYOffset || document.documentElement.scrollTop;
  requestTick();
}

function getWindowUnits() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function onResize() {
  getWindowUnits();
  requestTick();
}

function init() {
  initNavBarToggle();
  initAccountToggle();
  getWindowUnits();
  window.addEventListener('scroll', onScroll);
  window.addEventListener('resize', onResize);

  // pelcro events
  document.addEventListener('PelcroUserLogin', function () {
    siteHeader.classList.add('logged-in');
    pelcroToggle.classList.add('myAccount', 'myAccountBtn', 'myAccountHover');
    pelcroToggle.classList.remove('userLogin', 'userLoginBtn');

    navInner.classList.remove('logged-out');
    navInner.classList.add('logged-in');
    navInnerMobile.classList.remove('logged-out');
    navInnerMobile.classList.add('logged-in');

    digiSub.classList.remove('logged-out');
    digiSub.classList.add('logged-in');
    digiSubMobile.classList.remove('logged-out');
    digiSubMobile.classList.add('logged-in');
  });
  document.addEventListener('PelcroUserLogout', function () {
    siteHeader.classList.remove('logged-in');
    pelcroToggle.classList.remove('myAccount', 'myAccountBtn', 'myAccountHover');
    pelcroToggle.classList.add('userLogin', 'userLoginBtn');

    navInner.classList.remove('logged-in');
    navInner.classList.add('logged-out');
    navInnerMobile.classList.remove('logged-in');
    navInnerMobile.classList.add('logged-out');

    digiSub.classList.remove('logged-in');
    digiSub.classList.add('logged-out');
    digiSubMobile.classList.remove('logged-in');
    digiSubMobile.classList.add('logged-out');
  });
}

export default { init };
