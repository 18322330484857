import Publift from './publift';

/**
 * Is paragraph?
 *
 * - Determine if a node is a paragraph
 *
 * @param {object} referenceNode - a child node from the article
 *
 */
function isParagraph(referenceNode) {
  if (referenceNode == null) {
    return false;
  }
  return referenceNode.nodeType === 1 && referenceNode.nodeName.toLowerCase() === 'p';
}

/**
 * Contains image.
 *
 * - Determine if a node contains an image
 *
 * @param {object} referenceNode - a child node from the article
 *
 */
function containsImg(referenceNode) {
  return referenceNode.innerHTML.indexOf('img') !== -1;
}

/**
 * Is empty?
 *
 * - Determine if a node is empty
 *
 * @param {object} referenceNode - a child node from the article
 *
 */
function isEmpty(referenceNode) {
  return referenceNode.innerHTML.trim().length === 0;
}

/**
 * Valid paragraph.
 *
 * - Identify nodes that are paragraph elements, are not empty and don't contain an image
 *
 * @param {object} referenceNode - a child node from the article
 *
 */
function validParagraph(referenceNode) {
  if (isParagraph(referenceNode)
    && (!containsImg(referenceNode)) && (!isEmpty(referenceNode))) {
    return true;
  }
  return false;
}

/**
 * Previous contains image.
 *
 * - Identify nodes that are paragraph elements, are not empty and don't contain an image
 *
 * @param {object} referenceNode - a child node from the article
 *
 */
function previousContainsImg(referenceNode) {
  const previousNode = referenceNode.previousElementSibling;
  return containsImg(previousNode);
}

/**
 * Space before and after.
 *
 * - Check that the previous and next nodes are paragraphs
 *
 * @param {object} referenceNode - a child node from the article
 *
 */
function spaceBeforeAndAfter(referenceNode) {
  const nextNode = referenceNode.nextElementSibling;
  if (nextNode === null) {
    return false;
  }
  const afterNextNode = nextNode.nextElementSibling;
  if (typeof nextNode === 'undefined' || typeof afterNextNode === 'undefined') {
    return false;
  }
  return validParagraph(nextNode) && validParagraph(afterNextNode);
}

let adIndex = 0;

function getAdIndex() {
  adIndex += 1;
  if (adIndex > 4) {
    adIndex = 1;
  }
  return adIndex;
}

/**
 * Insert Article mrec zone and slot.
 */
function insertMrecZoneAndSlot(article, contentNode) {
  const nextIndex = getAdIndex();
  const tagPrefix = 'article_mrec_';
  const tag = tagPrefix + nextIndex;
  const advert = Publift.createPubliftAd(tag);
  const fuseTagId = Publift.fuseTags[tag];

  contentNode.parentNode.insertBefore(advert, contentNode.nextSibling);

  if (typeof fusetag === 'undefined') {
    return;
  }

  fusetag.que.push(function () {
    fusetag.loadFuseSlotById(fuseTagId);
  });
}

/**
 * Place mrec within article content.
 *
 * - Evaluate all child nodes of an article for potential ad placement
 * - Insert an initial medium rectangle after the number of characters specified in firstAdPadding
 * - Insert more medium rectangles after the number of characters set in density
 *
 * @param {object} article - the innerHTML of the article wrapper
 * @param {integer} density - the number of characters between ads
 * @param {integer} firstAdPadding - the number of characters before the first ad
 *
 */
function placeMrecWithinContent(article, density = 2500, firstAdPadding = 400) {
  const articleContent = article.querySelector('.js-insertArticleAdZones');
  if (!articleContent) {
    return;
  }

  const articleNodes = articleContent.childNodes;
  let charCount = 0;
  let firstAdnotInserted = true;

  // place the ads
  for (let i = 0; i < articleNodes.length; i += 1) {
    if (validParagraph(articleNodes[i])) {
      charCount += articleNodes[i].innerHTML.length;

      // place the first ad
      if (firstAdnotInserted && charCount > firstAdPadding
        && spaceBeforeAndAfter(articleNodes[i])) {
        insertMrecZoneAndSlot(article, articleNodes[i]);
        firstAdnotInserted = false;
        charCount = 0;
      }

      // place an ad according to density
      // check if previous paragraph contains an image so we
      // don't place an ad immediately after an image
      if (charCount > density && spaceBeforeAndAfter(articleNodes[i])
        && (!previousContainsImg(articleNodes[i]))) {
        insertMrecZoneAndSlot(article, articleNodes[i]);
        charCount = 0;
      }
    }
  }
}

function initArticleContentAds() {
  const article = document.querySelector('.article');
  if (article) {
    placeMrecWithinContent(article);
  }
}

function init() {
  initArticleContentAds(document);
}

export default {
  init,
  placeMrecWithinContent
};
