class InclusiveToggle {
  constructor(button) {
    this.button = button;
    this.target = document.getElementById(button.getAttribute('aria-controls'));
    this.siblings = null;
    if (button.classList.contains('bifteki')) {
      this.siblings = document.querySelectorAll('.bifteki');
    }
    if (button.classList.contains('myAccountBtn')) {
      this.siblings = document.querySelectorAll('.myAccountBtn');
    }
    if (button.classList.contains('userLoginBtn')) {
      this.siblings = document.querySelectorAll('.userLoginBtn');
    }
    return this;
  }

  open() {
    const event = document.createEvent('Event');
    event.initEvent('open', true, true);
    this.button.setAttribute('aria-expanded', true);
    if (this.target) {
      this.target.setAttribute('aria-hidden', false);
    }
    this.button.dispatchEvent(event);
    if (this.siblings != null) {
      this.siblings.forEach((btn) => {
        btn.setAttribute('aria-expanded', true);
        btn.setAttribute('aria-hidden', false);
        btn.dispatchEvent(event);
      });
    }
    return this;
  }

  close() {
    const event = document.createEvent('Event');
    event.initEvent('close', true, true);
    this.button.setAttribute('aria-expanded', false);
    if (this.target) {
      this.target.setAttribute('aria-hidden', true);
    }
    this.button.dispatchEvent(event);

    if (this.siblings != null) {
      this.siblings.forEach((btn) => {
        btn.setAttribute('aria-expanded', false);
        btn.setAttribute('aria-hidden', true);
        btn.dispatchEvent(event);
      });
    }
    return this;
  }

  toggle() {
    const expanded = this.button.getAttribute('aria-expanded') === 'true';
    return expanded ? this.close() : this.open();
  }

  init() {
    this.button.addEventListener('click', this.toggle.bind(this), false);
  }
}

export default InclusiveToggle;
