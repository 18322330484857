import 'element-closest';
import InfiniteScroll from 'infinite-scroll';
//import Advert from '../dfp/Advert';
import Publift from '../fuse/publift';
import ArticleAdverts from '../fuse/article';
import Gallery from './Gallery';
import PosterCaption from './PosterCaption';
import Loader from './Loader';
import InclusiveToggle from '../utilities/InclusiveToggle';

const Article = {};
Article.infScrollWrapper = document.getElementById('site-articles');
const siteMain = document.getElementById('site-main');

const strings = {
  truncateToggle: '<button class="truncated-toggle icon-arrow-down">Read More</button>'
};

/**
 * Track page load.
 *
 * @param HTMLAnchorElement An anchor containing the href of the current page.
 */
function trackPageLoad(link) {
  ga('set', 'page', link.pathname);
  ga('send', 'pageview');
}

/**
 * Track event.
 *
 * @param HTMLAnchorElement link An anchor containing the href of the current page.
 * @param string action A description of the action.
 */
function trackEvent(link, action) {
  ga('send', {
    hitType: 'event',
    eventCategory: 'Article',
    eventAction: action,
    eventLabel: link.pathname
  });
}

/**
 * Conditionally handle analytics tracking.
 *
 * @param String The event type to handle.
 */
function handleAnalytics(event) {
  if (typeof ga === 'undefined') {
    return;
  }

  const link = document.createElement('a');
  let url = new Url(Article.infScroll.getPath());
  // If running on localhost then fix urls creating cors errors
  if (window.document.location.host !== url.host) {
    url = new Url(url.toString().replace(url.host, window.document.location.host));
  }
  link.href = url.toString();

  if (event === 'truncated') {
    trackEvent(link, 'Infinite Scroll');
  } else if (event === 'shortArticle') {
    trackEvent(link, 'Infinite scroll');
    trackEvent(link, 'Short article');
  } else if (event === 'readMore') {
    trackEvent(link, 'Read more');
    trackPageLoad(link);
  }
}

/**
 * Init comment toggle.
 */
function initCommentToggle(article) {
  const commentToggleButton = article.querySelector('.commentToggle');

  if (commentToggleButton) {
    //
    const isPostingComment = (document.location.hash.search(/(comment|respond)/) !== -1);
    const commentToggle = new InclusiveToggle(commentToggleButton);
    commentToggle.init();

    if (isPostingComment) {
      commentToggle.open();
    }
  }
}

/*
 * Get datalayer vars from article
 */
function getVars(article) {
  const newVars = {};
  let attrib = null;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < article.attributes.length; i++) {
    attrib = article.attributes[i];
    if (attrib.name.indexOf('data-dl-') === 0) {
      const newKey = attrib.name.replace('data-dl-', '');
      newVars[newKey] = article.attributes[attrib.name].value;
    }
  }
  return newVars;
}
/*
 * DataLayer Initialise
 */
function dataLayerInit(article) {
  const dataLayer = window.dataLayer || [];
  const newVars = getVars(article);
  newVars.event = 'custom.page.scroll.bottom';
  // console.log('dataLayerInit', newVars);

  const target = article.querySelector('.post-footer');
  if (!target) {
    console.error('No target element found for scroll tracking.');
  }
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // console.log('dataLayerPush', newVars);
        dataLayer.push(newVars);
        observer.unobserve(entry.target);
      }
    });
  }, {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  });
  observer.observe(target);
}

function truncateToggle(e) {
  if (e.target.classList.contains('truncated-toggle')) {
    const article = e.target.closest('.article');
    const truncateWrapper = article.querySelector('.truncated');
    article.classList.remove('is-truncated');
    truncateWrapper.classList.add('active');
    //Advert.enableLazyLoading( article );
    Publift.loadAdsInSection(article);
    handleAnalytics('readMore');
    dataLayerInit(article);
  }
}

function truncateArticle(truncated) {
  const wrapper = document.createElement('div');

  wrapper.classList.add('truncated');
  // Insert the wrapper before the item to truncate.
  truncated.parentNode.insertBefore(wrapper, truncated);
  // then insert the item into the wrapper.
  wrapper.appendChild(truncated);
  wrapper.innerHTML += strings.truncateToggle;
}

function applyScripts(article) {
  Gallery.initGalleries(article[0]);
  initCommentToggle(article[0]);
}

/**
 * Prepare Article
 *
 * - Insert ad zones for all articles
 * - Truncate articles
 * - Create dfp ad slots for articles that aren't truncated
 *
 * @param {object} response - response body of the loaded request from infScroll
 *
 */
function prepareArticle(response) {
  const article = response.querySelector('.article');
  const truncated = article.querySelector('.js-truncate');

  // Insert medium rectangle zones in all articles.
  ArticleAdverts.placeMrecWithinContent(article);

  if (truncated) {
    truncateArticle(truncated);
    article.classList.add('is-truncated');
    handleAnalytics('truncated');
    const vars = getVars(article);
    const dataLayer = window.dataLayer || [];
    dataLayer.push(vars);
  } else {
    //Advert.enableLazyLoading( article );
    dataLayerInit(article);
    handleAnalytics('shortArticle');
  }
}

function infScrollPath() { // eslint-disable-line consistent-return
  const articles = document.querySelectorAll('.article');
  const lastArticle = articles[articles.length - 1];
  const permalink = lastArticle.getAttribute('data-prev-post');
  return permalink || false;
}

function initInfScroll() {
  return new InfiniteScroll(Article.infScrollWrapper, {
    path: infScrollPath,
    append: '.article',
    status: '.infscroll-status'
  });
}

Article.init = function init() {
  if (!Article.infScrollWrapper) {
    return;
  }

  const firstArticle = Article.infScrollWrapper.querySelector('.article');

  dataLayerInit(firstArticle);

  // Setup comment toggle.
  initCommentToggle(firstArticle);

  // Setup poster caption toggle.
  PosterCaption.initTogglePosterCaption(firstArticle);

  // Ensure that an article exists and that data-prev-post is not empty.
  if (firstArticle && firstArticle.getAttribute('data-prev-post')) {
    // Insert the loader.
    Loader.insertInfiniteScrollStatus(siteMain);

    // Infinite scroll.
    Article.infScroll = initInfScroll();

    // Append article.
    Article.infScroll.on('load', prepareArticle);

    // Ensure scripts work with loaded content.
    Article.infScroll.on('append', (response, path, items) => {
      applyScripts(items);
      Publift.loadAdsInSection(items[0]);
    });

    // Apply post caption toggle script.
    Article.infScroll.on('append', (response, path, items) => {
      PosterCaption.initTogglePosterCaption(items[0]);
    });

    // Truncated articles: toggle
    Article.infScrollWrapper.addEventListener('click', truncateToggle, false);
  } else {
    ArticleAdverts.placeMrecWithinContent(firstArticle);
  }
};

export default Article;
