import Flickity from 'flickity';

function initSliders(context) {
  const heroSliders = context.querySelectorAll('.hero-slider');

  [].forEach.call(heroSliders, (slider) => {
    let timeout = slider.getAttribute('data-timeout');
    timeout = (timeout !== '0') ? timeout : false;

    const flkty = new Flickity(slider, { // eslint-disable-line no-unused-vars
      cellAlign: 'left',
      setsliderSize: false,
      autoPlay: timeout,
      wrapAround: true,
      arrowShape: 'M32.7,50L59.5,0l7.7,4.2L42.6,50l24.6,45.8l-7.7,4.2L32.7,50z'
    });
  });
}

function init() {
  const siteMain = document.getElementById('site-main');
  initSliders(siteMain);
}

export default {
  init
};
