/* global twttr, FB, instgrm */
let documentContext = document;
const scriptsLoaded = {};

function loadScript(url, handle) {
  const script = documentContext.createElement('script');
  script.src = url;
  scriptsLoaded[handle] = true;
  documentContext.head.appendChild(script);
}

function loadExecuteTwitter() {
  if (window.twttr && twttr.widgets) {
    twttr.widgets.load();
    return;
  }

  if (scriptsLoaded.twitter) {
    return;
  }

  loadScript('//platform.twitter.com/widgets.js', 'twitter');
}

function loadExecuteFacebook() {
  if (window.FB) {
    FB.XFBML.parse();
    return;
  }

  if (scriptsLoaded.facebook) {
    return;
  }

  loadScript('//connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.11', 'facebook');
}

function loadExecuteInstagram() {
  if (window.instgrm) {
    instgrm.Embeds.process();
    return;
  }

  if (scriptsLoaded.instagram) {
    return;
  }

  loadScript('//platform.instagram.com/en_US/embeds.js', 'instagram');
}

function loadEmbed(el) {
  const twttrWidget = el.getAttribute('data-twitter');
  const fcbkWidget = el.getAttribute('data-facebook');
  const instgrmWidget = el.getAttribute('data-instagram');

  if (twttrWidget) {
    el.classList.add(twttrWidget);
    loadExecuteTwitter();
  } else if (fcbkWidget) {
    el.classList.add(fcbkWidget);
    loadExecuteFacebook();
  } else if (instgrmWidget) {
    el.classList.add(instgrmWidget);
    loadExecuteInstagram();
  }
}

/**
 * Load individual embeds on the lazybeforeunveil event.
 */
function init() {
  document.addEventListener('lazybeforeunveil', (e) => {
    loadEmbed(e.target);
  });
}

/**
 * Load all embeds in the admin editor upfront.
 */
function adminInit(editor) {
  if (!editor.contentDocument) {
    return;
  }

  documentContext = editor.contentDocument;

  // The whole document isn't available without the timeout.
  window.setTimeout(() => {
    const embeds = documentContext.querySelectorAll('.lazyload');

    [].forEach.call(embeds, (embed) => {
      loadEmbed(embed);
    });
  }, 500);
}

export default {
  init,
  adminInit
};
