import Publift from './publift';
import Feed from './feed';
import Article from './article';
import Home from './home';

function init() {
  if (typeof fusetag !== 'undefined') {
    //Set site targeting
    Publift.init();
    Feed.init();
    Article.init();
    Home.init();
  }

  Feed.closeAd();
}

export default {
  init
};
