import dfpConfig from './dfp-config';

const zoneDefinitions = {
  billboard: {
    size: [970, 250],
    sizeMapping: () => window.googletag.sizeMapping()
      .addSize(dfpConfig.breakPoints.desktop, [970, 250])
      .addSize(dfpConfig.breakPoints.mobile, [300, 250])
      .build()
  },
  mrec: {
    size: [300, 250]
  },
  premium: {
    size: [
      'fluid',
      [970, 250],
      [300, 250]
    ],
    sizeMapping: () => window.googletag.sizeMapping()
      .addSize(dfpConfig.breakPoints.desktop, [[970, 250], 'fluid'])
      .addSize(dfpConfig.breakPoints.mobile, [[300, 250], 'fluid'])
      .build()
  },
  header_premium: {
    size: [
      'fluid',
      [970, 250],
      [320, 100]
    ],
    sizeMapping: () => window.googletag.sizeMapping()
      .addSize(dfpConfig.breakPoints.desktop, [[970, 250], 'fluid'])
      .addSize(dfpConfig.breakPoints.mobile, [[320, 100], 'fluid'])
      .build()
  }
};

function getZonePrefix() {
  let prefix = 'nk';

  if (dfpConfig.language === 'el') {
    prefix += '_el';
  }

  if (dfpConfig.environment === 'development') {
    prefix = 'ztest';
  }

  return prefix;
}

function createZoneId(zoneType) {
  const prefix = getZonePrefix();
  const zoneId = `${prefix}_${zoneType}_${dfpConfig.zoneIds.length}`;
  dfpConfig.zoneIds.push(zoneId);
  return zoneId;
}

function getAdUnitPath(zoneId) {
  // Remove the index/count from the adZone ID.
  const pos = zoneId.lastIndexOf('_');
  const adUnit = zoneId.substring(0, pos);
  return `/${dfpConfig.accountId}/${adUnit}`;
}

function createZone(zoneType, params) {
  const options = {
    deferLoading: false,
    keyValues: {},
    ...params
  };

  const zoneClasses = [
    dfpConfig.zoneClass,
    'adZone',
    `adZone--${zoneType}`,
    'lazyunload'
  ];

  if (!options.deferLoading) {
    zoneClasses.push('lazyload');
  }

  const zone = document.createElement('div');
  zone.id = createZoneId(zoneType);

  // spread would be nicer, but ie10 :(
  zoneClasses.forEach((className) => {
    zone.classList.add(className);
  });

  zone.setAttribute('data-zone-type', zoneType);

  Object.keys(options.keyValues)
    .forEach((key) => {
      zone.setAttribute(`data-${key}`, options.keyValues[key]);
    });

  return zone;
}

/**
 * Create ad slot.
 *
 * @link https://developers.google.com/doubleclick-gpt/reference
 */
function createSlot(zoneId) {
  const zone = document.getElementById(zoneId);

  if (!zone) {
    return;
  }

  const adUnitPath = getAdUnitPath(zoneId);
  const zoneType = zone.getAttribute('data-zone-type');

  window.googletag.cmd.push(() => {
    dfpConfig.adSlots[zoneId] = window.googletag
      .defineSlot(adUnitPath, zoneDefinitions[zoneType].size, zoneId)
      .addService(window.googletag.pubads());

    /**
     * Set key value targeting.
     *
     * @link https://support.google.com/admanager/answer/188092
     */
    dfpConfig.targetingKeys.forEach((key) => {
      const dataAttr = zone.getAttribute(`data-${key}`);
      if (dataAttr) {
        dfpConfig.adSlots[zoneId].setTargeting(key, dataAttr.split(','));
      }
    });

    /**
     * Define size mapping for the different breakpoints.
     *
     * @link https://developers.google.com/doubleclick-gpt/reference#googletag.Slot_defineSizeMapping
     */
    if (zoneDefinitions[zoneType].sizeMapping) {
      dfpConfig.adSlots[zoneId].defineSizeMapping(zoneDefinitions[zoneType].sizeMapping());
      zone.classList.add(`${dfpConfig.zoneClass}--responsive`);
    }
  });
}

function displaySlot(zoneId) {
  window.googletag.cmd.push(() => {
    window.googletag.display(zoneId);
    window.googletag.pubads()
      .refresh([dfpConfig.adSlots[zoneId]]);
  });
}

function enableLazyLoading(article) {
  const zones = article.querySelectorAll(dfpConfig.zoneSelector());
  for (let i = 0; i < zones.length; i += 1) {
    zones[i].classList.add('lazyload');
  }
}

export default {
  createZone,
  createSlot,
  displaySlot,
  enableLazyLoading
};
