import { Loader } from '@googlemaps/js-api-loader';

function init() {
  const loader = new Loader({
    apiKey: 'AIzaSyBNIbindM3kDeb-OtYuTzfatAcpPYYBP7Q',
    version: 'weekly',
    libraries: ['places']
  });
  document.addEventListener('DOMContentLoaded', () => {
    loader.load().then((google) => {
      const maps = document.querySelectorAll('.acf-map');
      maps.forEach((map) => {
        // eslint-disable-next-line no-new
        const initMap = new google.maps.Map(map, {
          center: {
            lat: map.dataset.lat ? parseFloat(map.dataset.lat) : 0,
            lng: map.dataset.lng ? parseFloat(map.dataset.lng) : 0
          },
          zoom: map.dataset.zoom ? parseFloat(map.dataset.zoom) : 14,
          styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f5f5f5'
                }
              ]
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#616161'
                }
              ]
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#f5f5f5'
                }
              ]
            },
            {
              featureType: 'administrative.land_parcel',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#bdbdbd'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#eeeeee'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#757575'
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#e5e5e5'
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#9e9e9e'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#ffffff'
                }
              ]
            },
            {
              featureType: 'road.arterial',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#757575'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#dadada'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#616161'
                }
              ]
            },
            {
              featureType: 'road.local',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#9e9e9e'
                }
              ]
            },
            {
              featureType: 'transit.line',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#e5e5e5'
                }
              ]
            },
            {
              featureType: 'transit.station',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#eeeeee'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#c9c9c9'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#9e9e9e'
                }
              ]
            }
          ]
        });
        // eslint-disable-next-line no-new
        const marker = new google.maps.Marker({
          position: {
            lat: map.dataset.lat ? parseFloat(map.dataset.lat) : 0,
            lng: map.dataset.lng ? parseFloat(map.dataset.lng) : 0
          }
        });
        marker.setMap(initMap);

        google.maps.event.addListener(marker, 'click', function () {
          window.open(`https://www.google.com/maps?saddr=My+Location&daddr=${map.dataset.address}`, '_blank');
        });
      });
    });
  });
}

export default {
  init
};
