function togglePrice(firstPrices, secondPrices) {
  firstPrices.forEach((firstPrice) => {
    if (firstPrice.classList.contains('active')) {
      firstPrice.classList.remove('active');
    } else {
      firstPrice.classList.add('active');
    }
  });

  secondPrices.forEach((secondPrice) => {
    if (secondPrice.classList.contains('active')) {
      secondPrice.classList.remove('active');
    } else {
      secondPrice.classList.add('active');
    }
  });

  // subscribeButtons.forEach((subscribeButton) => {
  //   if (subscribeButton.dataset.currentPlan === 'plan-first') {
  //     // eslint-disable-next-line no-param-reassign
  //     subscribeButton.dataset.currentPlan = 'plan-second';
  //   } else {
  //     // eslint-disable-next-line no-param-reassign
  //     subscribeButton.dataset.currentPlan = 'plan-first';
  //   }
  // });
}

function init() {
  const nkSubscriptions = document.querySelectorAll('.nk-subscription-plans');
  if (nkSubscriptions.length > 0) {
    //remove unnecessary padding
    document.querySelector('.site-main').classList.add('no-padding');
  }
  const toggleSwitches = document.querySelectorAll('.switch > .toggle');
  toggleSwitches.forEach((toggleSwitch) => {
    toggleSwitch.addEventListener('change', function () {
      const firstLabel = this.parentElement.parentElement.querySelector('.first-label');
      const secondLabel = this.parentElement.parentElement.querySelector('.second-label');
      const firstPrices = this.parentElement.parentElement.parentElement.querySelectorAll('.first-price');
      const secondPrices = this.parentElement.parentElement.parentElement.querySelectorAll('.second-price');
      const subscribeButtonFirst = document.querySelectorAll('.nk-subscription-plans .subscribe-button .first');
      const subscribeButtonSecond = document.querySelectorAll('.nk-subscription-plans .subscribe-button .second');
      // eslint-disable-next-line max-len
      // const subscribeButtons = this.parentElement.parentElement.parentElement.querySelectorAll('.sub-button');
      if (this.checked) {
        if (firstLabel) {
          firstLabel.classList.remove('active');
        }
        if (secondLabel) {
          secondLabel.classList.add('active');
        }
        if (subscribeButtonFirst.length) {
          subscribeButtonFirst.forEach((button) => {
            button.classList.remove('active');
          });
        }
        if (subscribeButtonSecond.length) {
          subscribeButtonSecond.forEach((button) => {
            button.classList.add('active');
          });
        }
      } else {
        if (firstLabel) {
          firstLabel.classList.add('active');
        }
        if (secondLabel) {
          secondLabel.classList.remove('active');
        }
        if (subscribeButtonFirst.length) {
          subscribeButtonFirst.forEach((button) => {
            button.classList.add('active');
          });
        }
        if (subscribeButtonSecond.length) {
          subscribeButtonSecond.forEach((button) => {
            button.classList.remove('active');
          });
        }
      }
      togglePrice(firstPrices, secondPrices);
    });
  });

  // const subscribeButtons = document.querySelectorAll('.sub-button');
  // subscribeButtons.forEach((subscribeButton) => {
  //   subscribeButton.addEventListener('click', async function (event) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     console.log(subscribeButton.dataset);
  //     const {
  //       currentPlan, planIdFirst, productIdFirst, planIdSecond, productIdSecond
  //     } = subscribeButton.dataset;
  //     let productId = productIdFirst;
  //     let planId = planIdFirst;
  //
  //     if (currentPlan === 'plan-second') {
  //       productId = productIdSecond;
  //       planId = planIdSecond;
  //     }
  //
  //     if (window.pelcroActions && productId && planId) {
  //       console.log(parseInt(productId, 10), parseInt(planId, 10));
  //       await window.pelcroActions.setProduct(parseInt(productId, 10));
  //       await window.pelcroActions.setPlan(parseInt(planId, 10));
  //       await window.pelcroActions.subscriptionCreate();
  //     }
  //   });
  // });
}
export default {
  init
};
