import Publift from './publift';

let adIndex = 0;

function getAdIndex() {
  adIndex += 1;
  if (adIndex > 4) {
    adIndex = 1;
  }
  return adIndex;
}

/**
 * Insert Article mrec zone and slot.
 */
function placeHomeMrec(zone) {
  const nextIndex = getAdIndex();
  const tagPrefix = 'home_mrec_';
  const tag = tagPrefix + nextIndex;
  const advert = Publift.createPubliftAd(tag);
  const fuseTagId = Publift.fuseTags[tag];

  // eslint-disable-next-line no-param-reassign
  zone.appendChild(advert);

  fusetag.que.push(function () {
    fusetag.loadFuseSlotById(fuseTagId);
  });
}

function initHomeMrecAds() {
  const zone = document.querySelectorAll('.js-mrec-home');
  if (zone) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < zone.length; i++) {
      placeHomeMrec(zone[i]);
    }
  }
}

function init() {
  initHomeMrecAds(document);
}

export default {
  init,
  initHomeMrecAds
};
