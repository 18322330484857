import InfiniteScroll from 'infinite-scroll';
import Loader from './Loader';

const Feed = {};
Feed.infScrollWrapper = document.getElementById('feed-main');
const feedWrapper = document.getElementById('feed');
const feedPagination = document.querySelector('.pagination');
const loadMoreButton = document.createElement('button');
const loadCount = 3;

function initInfScroll() {
  return new InfiniteScroll(Feed.infScrollWrapper, {
    path: 'a.next.page-numbers',
    append: '.feed-item',
    history: false,
    hideNav: '.pagination-nav',
    status: '.infscroll-status'
  });
}

function revertToManualLoading() {
  if (Feed.infScroll.loadCount % loadCount === 0) {
    Feed.infScroll.option({
      loadOnScroll: false
    });

    // Insert load more button.
    loadMoreButton.className = 'btn btn--neoskosmos feed-load-more';
    loadMoreButton.textContent = 'Load More';
    feedPagination.appendChild(loadMoreButton);
  }
}

function removeLoadMoreButton() {
  if (loadMoreButton.parentNode) {
    loadMoreButton.parentNode.removeChild(loadMoreButton);
  }
}

function handleManualLoading() {
  Feed.infScroll.loadNextPage();
  removeLoadMoreButton();
  Feed.infScroll.option({
    loadOnScroll: true
  });
}

Feed.init = function init() {
  if (!Feed.infScrollWrapper) {
    return;
  }

  // Setup infinite scroll.
  if (feedPagination) {
    // Insert the loader.
    Loader.insertInfiniteScrollStatus(feedWrapper);

    // Infinite scroll.
    Feed.infScroll = initInfScroll();

    // Load one page on scroll and then switch to manual loading via a button.
    Feed.infScroll.on('load', revertToManualLoading);

    // Load next page on click.
    loadMoreButton.addEventListener('click', handleManualLoading);

    // Remove the Load more button on the last page.
    Feed.infScroll.on('last', removeLoadMoreButton);
  }
};

export default Feed;
