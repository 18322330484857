const dfpConfig = {
  accountId: '1051519',
  environment: 'production',
  language: 'en',
  pagetype: false,
  zoneClass: 'js-adZone',
  zoneSelector: () => `.${dfpConfig.zoneClass}`,
  breakPoints: {
    mobile: [0, 0],
    desktop: [1100, 600]
  },
  targetingKeys: [
    'category',
    'context'
  ],
  zoneIds: [],
  adSlots: {},
  ...window.nkDfpConfig
};

export default dfpConfig;
