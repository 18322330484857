import Feed from '../components/Feed';
import Publift from './publift';

let startingHomeIndex = 4; //Starting index
let startingCategoryIndex = 1;

function getHomeIndex() {
  if (startingHomeIndex === 3) {
    startingHomeIndex = 4;
  } else {
    startingHomeIndex = 3;
  }
  return startingHomeIndex;
}

function getCategoryIndex() {
  startingCategoryIndex += 1;
  if (startingCategoryIndex > 3) {
    startingCategoryIndex = 1;
  }
  return startingCategoryIndex;
}

function insertHomeFeedAds(nextIndex) {
  const ads = Feed.infScrollWrapper.getElementsByClassName('adUnit');
  const lastAd = ads[ads.length - 1];
  const tagPrefix = 'home_incontent_';
  const tag = tagPrefix + nextIndex;
  const advert = Publift.createPubliftAd(tag);
  const fuseTagId = Publift.fuseTags[tag];

  let insertIndex = Array.from(lastAd.parentNode.children)
    .indexOf(lastAd);
  insertIndex += 1;
  let sibling = lastAd.nextSibling;
  let counter = 0;

  while (sibling) {
    counter += 1;
    insertIndex += 1;
    sibling = sibling.nextSibling;
    if (counter >= 5) {
      Feed.infScrollWrapper.insertBefore(advert, Feed.infScrollWrapper.children[insertIndex]);
      break;
    }
  }

  fusetag.que.push(function () {
    fusetag.loadFuseSlotById(fuseTagId);
  });

  //Check if we need to add another
  const totalItems = Feed.infScrollWrapper.children.length;
  if ((totalItems - 5) > insertIndex) {
    //Run again
    const newIndex = getHomeIndex();
    insertHomeFeedAds(newIndex);
  }
}

function insertPubliftFeedAd() {
  let nextIndex = 1;
  let tagPrefix = 'home_incontent_';
  if (nkFuseConfig.pagetype === 'category') {
    tagPrefix = 'category_incontent_';
    nextIndex = getCategoryIndex();
  } else {
    nextIndex = getHomeIndex();
  }
  const tag = tagPrefix + nextIndex;
  const advert = Publift.createPubliftAd(tag);
  const fuseTagId = Publift.fuseTags[tag];
  if (nkFuseConfig.pagetype === 'category') {
    Feed.infScrollWrapper.appendChild(advert);

    fusetag.que.push(function () {
      fusetag.loadFuseSlotById(fuseTagId);
    });
  } else if (Feed.infScrollWrapper.children.length > 5) {
    const ads = Feed.infScrollWrapper.getElementsByClassName('adUnit');
    if (ads.length > 0) {
      insertHomeFeedAds(nextIndex);
    }
  }
}

function closeAd() {
  const closeTrigger = document.querySelectorAll('.ad-sticky-mobile__close');

  for (let i = 0; i < closeTrigger.length; i += 1) {
    closeTrigger[i].addEventListener('click', function (e) {
      e.target.parentNode.classList.add('ad-sticky-mobile--disabled');
    });
  }
}

function init() {
  if (Feed.infScrollWrapper) {
    Feed.infScroll.on('append', insertPubliftFeedAd);
  }
}

export default {
  init,
  closeAd
};
