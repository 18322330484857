function init() {
  const contentSections = document.querySelectorAll('.content-section-title');
  contentSections.forEach((section) => {
    section.addEventListener('click', function (event) {
      event.stopPropagation();
      const parent = section.parentNode;
      if (!parent.classList.contains('active')) {
        parent.classList.add('active');
      } else {
        parent.classList.remove('active');
      }
    });
  });
}

export default {
  init
};
