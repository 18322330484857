const siteMain = document.getElementById('site-main');

function socialWindow(url, size) {
  // @link https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
  const shareWindow = window.open(url, '', size);
  shareWindow.opener = null;
}

function init() {
  siteMain.addEventListener('click', (e) => {
    if (e.target.classList.contains('js-social-share')) {
      e.preventDefault();
      const sizeAttr = e.target.getAttribute('data-window-size');
      const size = sizeAttr || '';
      socialWindow(e.target.href, size);
    }
  }, false);
}

export default { init };
