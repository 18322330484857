export default {
  init() {
    const dataLayer = window.dataLayer || [];
    if (!window.dataLayerVars) {
      return;
    }

    // user subscription status
    let isSubscribed = false;
    if (window.Pelcro) {
      isSubscribed = window.Pelcro.subscription.isSubscribedToSite();
    }

    window.dataLayerVars.subscriber = isSubscribed;
    // console.log('dataLayerPush', window.dataLayerVars);
    dataLayer.push(window.dataLayerVars);
  }
};
