import './components/LazySizesConfig';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/unload/ls.unload';
import 'lazysizes';
import NavBar from './components/NavBar';
import Embeds from './components/Embeds';
import Feed from './components/Feed';
import Article from './components/Article';
import Gallery from './components/Gallery';
///import LoadAds from './dfp/LoadAds';
import FuseAds from './fuse/LoadAds';
// import Subscribe from './components/Subscribe';
import PostPreview from './components/PostPreview';
import PosterCaption from './components/PosterCaption';
import SocialShare from './components/SocialShare';
import PageModules from './components/PageModules';
import SearchResults from './components/SearchResults';
import SingleNoticesTributes from './components/SingleNoticesTributes';
import GoogleMaps from './components/GoogleMap';
import SubscriptionPlansToggle from './components/SubscriptionPlansToggle';
import gtm from './utilities/gtm';

NavBar.init();
Embeds.init();
Feed.init();
Article.init();
Gallery.init();
FuseAds.init();
//Subscribe.init();
PostPreview.init();
PosterCaption.init();
SocialShare.init();
PageModules.init();
SearchResults.init();
SingleNoticesTributes.init();
GoogleMaps.init();
SubscriptionPlansToggle.init();
// RCP.init();
gtm.init();
