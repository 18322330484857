import 'element-closest';

const siteMain = document.getElementById('site-main');

function makePostPreviewClickable(e, post) {
  const link = post.querySelector('.entry-title a');

  if (link) {
    e.preventDefault();
    if (!e.ctrlKey && !e.metaKey) {
      window.location = link;
    } else {
      // Open in new window/tab.
      window.open(link);
    }
  }
}

function init() {
  siteMain.addEventListener('click', (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
      return;
    }

    if (e.target.classList.contains('posterCaption-btn')) {
      return;
    }

    const post = e.target.closest('.type-post');

    if (post) {
      makePostPreviewClickable(e, post);
    }
  }, false);
}

export default { init };
