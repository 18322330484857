const Strings = {
  loaderHTML: `
    <div class="loader">
      <div class="loader-dot"></div>
      <div class="loader-dot"></div>
      <div class="loader-dot"></div>
      <div class="loader-dot"></div>
    </div>`
};

Strings.infiniteScrollStatusHTML = `
  <div class="infscroll-status">
    ${Strings.loaderHTML}
  </div>`;

function returnNodeFromString(string) {
  return document.createRange()
    .createContextualFragment(Strings[string]);
}

function insertInfiniteScrollStatus(target) {
  const statusHTML = returnNodeFromString('infiniteScrollStatusHTML');
  statusHTML.querySelector('.loader')
    .classList
    .add('infinite-scroll-request');
  target.appendChild(statusHTML);
}

export default {
  insertInfiniteScrollStatus
};
