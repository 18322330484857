import InclusiveToggle from '../utilities/InclusiveToggle';

const home = document.body.classList.contains('home');
const siteMain = document.getElementById('site-main');
const takeover = siteMain.querySelector('.takeover');
const posters = siteMain.querySelectorAll('.poster');

function initTogglePosterCaption(referenceNode) {
  const toggleButton = referenceNode.querySelector('.posterCaption-btn');

  if (!toggleButton) {
    return;
  }

  const posterCaptionToggle = new InclusiveToggle(toggleButton);
  posterCaptionToggle.init();
}

function init() {
  if (!home) {
    return;
  }

  // Init home page poster captions.
  if (posters) {
    for (let i = 0; i < posters.length; i += 1) {
      initTogglePosterCaption(posters[i]);
    }
  }

  // Init takeover caption.
  if (takeover) {
    initTogglePosterCaption(takeover);
  }
}

export default {
  init,
  initTogglePosterCaption
};
