const fuseTags = {
  default: false,
  home_header: '22064023813',
  home_mrec_1: '22064023819',
  home_mrec_2: '22064118444',
  home_incontent_1: '22064023822',
  home_incontent_2: '22064023816',
  home_incontent_3: '22064023825',
  home_incontent_4: '22064118447',
  mob_footer_sticky: '22064023828',
  article_header: '22064118450',
  article_mrec_1: '22064120046',
  article_mrec_2: '22064118453',
  article_mrec_3: '22118075126',
  article_mrec_4: '22118075132',
  article_vrec_1: '22064023834',
  article_footer: '22064023837',
  category_header: '22064120052',
  category_incontent_1: '22064120049',
  category_incontent_2: '22064118456',
  category_incontent_3: '22064120055'
};

function createPubliftAd(type) {
  const advert = document.createElement('div');
  const fuseTag = fuseTags[type];

  advert.classList.add('adUnit');
  advert.classList.add(`adUnit--${type}`);
  advert.innerHTML = `<div data-fuse="${fuseTag}"></div><small>Advertisement</small>`;
  return advert;
}

function init() {
  //Sets the site language / targeting
  let siteLanguage = nkFuseConfig.language;
  if (siteLanguage === 'en') {
    siteLanguage = 'english';
  } else {
    siteLanguage = 'greek';
  }

  fusetag.setTargeting('site', siteLanguage);
}

function loadTag(fuseTagId) {
  if (typeof fusetag === 'undefined') {
    return;
  }

  fusetag.que.push(function () {
    fusetag.loadFuseSlotById(fuseTagId);
  });
}

function loadAdsInSection(wrapper) {
  const ads = wrapper.getElementsByClassName('adUnit');
  if (ads.length > 0) {
    for (let i = 0; i < ads.length; i += 1) {
      const fuse = ads[i].getElementsByTagName('div');
      const fuseTagId = fuse[0].getAttribute('data-fuse');
      loadTag(fuseTagId);
    }
  }
}

export default {
  init,
  createPubliftAd,
  fuseTags,
  loadAdsInSection
};
